import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: './filter-by-load-number-modal.component.html',
    styleUrls: ['./filter-by-load-number-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class FilterByLoadNumberModalComponent {
  loadNumberListString: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string, 
    private dialogRef: MatDialogRef<FilterByLoadNumberModalComponent>)
  {
    this.loadNumberListString = data
  }

  saveFilter() {
    this.dialogRef.close(
      new LoadNumberFilter({
        loadNumberListString: this.loadNumberListString, 
        toRemove: false
      })
    );
  }

  removeFilter() {
    this.dialogRef.close(
      new LoadNumberFilter({
        loadNumberListString: "", 
        toRemove: true
      })
    );
  }
}

export class LoadNumberFilter {
  constructor(init?: Partial<LoadNumberFilter>) {
    Object.assign(this, init);
  }
  loadNumberListString: string = "";
  toRemove: boolean = false;
}
