<h4 mat-dialog-title>Filter by Load Number</h4>

<mat-dialog-content>
    <form #loadForm="ngForm">
        <mat-form-field>
            <mat-label>Load Number</mat-label>
            <textarea matInput rows="5" [(ngModel)]="loadNumberListString" name="loadNumberListString" pattern="[0-9]+(,[0-9]+)*" required></textarea>
            <mat-hint>You can input comma separated values to include multiple shipments</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close color="basic" class="dialog-footer-buttons">Cancel</button>
    <button mat-raised-button color="warn" class="dialog-footer-buttons" (click)="removeFilter()">Remove</button>
    <button mat-raised-button color="primary" class="dialog-footer-buttons" [disabled]="!loadForm?.valid" (click)="saveFilter()">Save</button>
</mat-dialog-actions>
