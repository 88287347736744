<div class="font-sans overflow-hidden flex flex-col sm:rounded-lg sm:shadow h-full">
  <div class="bg-white pl-5 py-5 border-b border-gray-200 sm:pl-6">
    <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-4 text-2xl leading-6 font-medium items-center text-gray-900">
        Shipments
        <div class="text-sm text-gray-400 mt-2">
          @if (filterTitle) {
            @for (filter of filterTitle.split(','); track filter) {
              <span class="inline-flex rounded-full items-center py-0.5 px-2.5 mr-1 text-sm font-medium bg-sky-100 text-sky-700">
                {{filter}}
              </span>
            }
          }
          @if (!filterTitle) {
            @for (filter of selectedFilters; track filter) {
              <span class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
                {{filter.name}}
                <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white" (click)="deleteFilter(filter)">
                  <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
              </span>
            }
          }
          @if (dateFilter && dateFilter.loadNumbers) {
            <span [matTooltip]="'Configure at the top right menu'" class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-rose-100 text-rose-700">
              {{dateFilter.loadNumbers.split(",").length}} Load Numbers
              <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-rose-400 hover:bg-rose-200 hover:text-rose-500 focus:outline-none focus:bg-rose-500 focus:text-white" (click)="deleteLoadFilter()">
                <span class="sr-only">Remove Load Numbers Filter</span>
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          }
          @if (filterTitle) {
            <span class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-gray-100 text-gray-700">
              Clear All
              <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white" (click)="deleteDrillDown()">
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          }
          @if (!filterTitle && selectedFilters.length > 0) {
            <span class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-gray-100 text-gray-700">
              Clear All
              <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white" (click)="clearUserDefinedFilters()">
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          }
        </div>
      </div>
      <div class="ml-4 mt-4 flex flex-col sm:flex-row sm:flex-grow-0 gap-2 items-center filters">
        @if (!noCustomer) {
          @if ((isASMUser || viewCustomerIds.length > 1) && dateFilter) {
            <sn-customer-selector [accessLevel]="accessLevelEnum.View" (customer)="customerSelected($event);" [selectedCustomerId]="dateFilter.customerId" class="flex w-full md:w-72"></sn-customer-selector>
          }
        }
        @if (!noDate) {
          <sn-date-filter-selector class="flex" [dateFilter]="dateFilter" (dateChanged)="dateChanged($event)"></sn-date-filter-selector>
        }
        <mat-form-field class="flex search" [class.hidden]="selectedFilters.length > 0 && !filterTitle">
          <mat-label>Search Shipments</mat-label>
          <input matInput [(ngModel)]="searchTerm" #search>
          <mat-icon matSuffix class="cursor-pointer" (click)="searchData()">search</mat-icon>
        </mat-form-field>
        <div class="flex h-[100px]">
          <button mat-icon-button class="mt-2" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="export" (click)="exportToExcel()">
              <i class="far fa-file-excel mr-2"></i>
              Export to Excel
            </button>
            <button mat-menu-item (click)="filterByLoadNumber()">
              <i class="far fa-filter mr-2"></i>
              Filter by Load Number
            </button>
            <div class="mat-menu-item">
              <mat-checkbox [(ngModel)]="showAll" (change)="refreshData();">Show All</mat-checkbox>
            </div>
            @if (isASMUser) {
              <button mat-menu-item [matMenuTriggerFor]="actions">Actions</button>
            }
          </mat-menu>
          <mat-menu #actions="matMenu">
            <button mat-menu-item (click)="bulkSMS()">Bulk SMS</button>
          </mat-menu>
          <mat-menu #export="matMenu">
            <button mat-menu-item (click)="exportLineItemsToExcel()">
              <i class="far fa-list mr-2"></i>
              Include Line Item Detail
            </button>
            <button mat-menu-item (click)="exportToExcel()">
              <i class="far fa-horizontal-rule mr-2"></i>
              Include Line Item Summary
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-grow bg-white overflow-auto" [class.hidden]="hideTable">
    <table mat-table [dataSource]="data" matSort [trackBy]="trackLoad" [matSortActive]="savedState.sortColumn" [matSortStart]="savedState.sortDirection" matSortDisableClear>
      @for (column of selectedColumns; track trackColumn($index, column)) {
        <ng-container [matColumnDef]="column.columnName">
          <th mat-header-cell [mat-sort-header]="column.columnName" *matHeaderCellDef [disabled]="!column.sortable">
            <!-- <ng-container [ngSwitch]="column.columnName">
            <ng-container *ngSwitchCase="'customerCarrierCost'">
              <ng-container *ngIf="isASMUser">{{column.label}}</ng-container>
              <ng-container *ngIf="!isASMUser">{{column.label.replace('Customer','')}}</ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>{{column.label}}</ng-container>
          </ng-container> -->
          {{column.label}}
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="isASMUser && cellHasFlag(column, element) ? 'bg-red-100 border-x-red-200' : ''">
          @switch (column.columnName) {
            @case ('loadNumber') {
              <a class="link" [routerLink]="['/shipments', element.loadNumber]">{{element.loadNumber}}</a>
            }
            @case ('carrierName') {
              <a class="link mr-1" [routerLink]="['/shipments/carrier', element.carrierID, element.carrierName]">{{element.carrierName}}</a>
              @if (element.carrierID) {
                <sn-carrier-info-tooltip [carrierId]="element.carrierID"></sn-carrier-info-tooltip>
              }
            }
            @case ('lastDispatcher') {
              <a class="link" [routerLink]="['/shipments/lastDispatcher', element.lastDispatcher]">{{element.lastDispatcher}}</a>
            }
            @case ('originalDispatcher') {
              <a class="link" [routerLink]="['/shipments/originalDispatcher', element.originalDispatcher]">{{element.originalDispatcher}}</a>
            }
            @case ('billNumber') {
              <a class="link" href="https://6030725.app.netsuite.com/app/accounting/transactions/vendbill.nl?id={{element.vendorBillID}}&whence=" target="_blank">{{element.billNumber}}</a>
            }
            @case ('originState') {
              {{getOriginState(element)}}
            }
            @case ('destinationState') {
              {{getDestinationState(element)}}
            }
            @case ('invoiceNumber') {
              @if (isASMUser) {
                <a class="link"  href="https://6030725.app.netsuite.com/app/accounting/transactions/custinvc.nl?id={{element.invoiceID}}&whence=" target="_blank">{{element.invoiceNumber}}</a>
              }
              @if (!isASMUser) {
                <span>{{element.invoiceNumber}}</span>
              }
            }
            @case ('documents') {
              <i class="far fa-file-download text-xl hover:text-blue-400 cursor-pointer" (click)="openDocumentDetails(element.loadNumber, element.serviceType, element)"></i>
            }
            @case ('tripStatus') {
              @if (element.loadType == loadTypes.API || element.loadType == loadTypes.QuantumView || element.loadType == loadTypes.Custom;) {
                <span class="whitespace-nowrap" [matTooltip]="element.currentStatus" (click)="openTrackingDetails(element.loadNumber)">
                  <a class="link mr-1">{{element.tripStatus}}</a>
                  <i  class="inline far fa-info-circle hover:text-blue-400 cursor-pointer"></i>
                </span>
              } @else {
                <span>
                  {{element.tripStatus}}
                </span>
              }
            }
            @case ('loadOptions') {
              <span class="load-options" [innerHTML]="element.loadOptions | loadoptions:loadOptionValues">
              </span>
            }
            @default {
              <sn-grid-column [column]="column" [element]="element"></sn-grid-column>
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="savedState.columns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: savedState.columns;"></tr>
  </table>
</div>
<mat-paginator class="flex justify-self-start bg-gray-50" [class.hidden]="hideTable" #matPaginator [pageIndex]="savedState.page" [pageSize]="savedState.pageSize" [pageSizeOptions]="[25, 50, 100, 250]" [length]="totalRecords" [showFirstLastButtons]="true"></mat-paginator>
@if (!hideNoData) {
  <div class="h-full flex items-center justify-center bg-white">
    <sn-material-grid-empty-table
      [tableName]="'Shipments'"
      [addButtonText]="'Shipment'"
      [showAddButton]="hasAddShipmentPermission"
      [hasFilter]="true"
      (addData)="newShipment()"
      (filter)="filterButtonClick()"
      >
    </sn-material-grid-empty-table>
  </div>
}
@if (!hideNoColumnSelected) {
  <div class="h-full flex items-center justify-center bg-white">
    <sn-material-grid-empty-columns (filter)="filterButtonClick()">
    </sn-material-grid-empty-columns>
  </div>
}
</div>
<button *hasPermission="[permissions.SHIPMENTS_CREATE]" mat-fab class="fab" color="primary" aria-label="Add new user" routerLink="/shipments/new">
  <mat-icon>add</mat-icon>
</button>
@if (!filterTitle) {
  <sn-grid-filter-slide-out
    listType="{{listTypesEnum.Shipments}}"
    [filters]="filters"
    [columns]="selectedColumns"
    [state]="savedState"
    (filterSelected)="filterSelected($event)"
    (filterDeleted)="deleteFilter($event)"
    (columnOrdered)="columnOrdered($event)"
    (columnToggled)="columnToggled($event)"
    (refreshFilters)="refreshFilters()"
    (resetSelectedColumns)="resetSelectedColumns()">
  </sn-grid-filter-slide-out>
}
