<h4 mat-dialog-title>Send Bulk SMS</h4>
<mat-dialog-content>
  <form #loadForm="ngForm">
    @if (invalidLoadNumberCount > 0) {
      <div class="rounded-md bg-red-50 p-4 mb-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/x-circle -->
            <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">There were {{invalidLoadNumberCount}} errors out of {{loadNumbers.length}} selected Shipments</h3>
            <div class="mt-2 text-sm text-red-700">
              <ul role="list" class="list-disc pl-5 space-y-1">
                @for (error of errors; track error) {
                  <li>
                    {{error.loadNumbers.length}} {{error.errorMessage}}
                    @if (error.loadNumbers.length <= 10) {
                      <span class="ml-1 font-bold">
                        ( @for (loadnumber of error.loadNumbers; track loadnumber; let indexOfelement = $index) {
                        <span>
                          <a [routerLink]="['/shipments', loadnumber]" target="_blank">
                            {{indexOfelement >= error.loadNumbers.length - 1 ? loadnumber : loadnumber + "," }}
                          </a>
                        </span>
                        } )
                      </span>
                    }
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    }
    <mat-form-field>
      <mat-label>Message</mat-label>
      <mat-hint>
        Use {{"{{LoadNumber}}"}} to insert the Load Number
      </mat-hint>
      <textarea [(ngModel)]="message" name="message" matInput placeholder="Your message here..." required></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="dialog-footer-buttons">Cancel</button>
  <button mat-raised-button color="primary" class="dialog-footer-buttons" [disabled]="!loadForm?.valid || loadNumbers.length <= invalidLoadNumberCount" (click)="sendSMS()">Send</button>
</mat-dialog-actions>
