import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ShipmentValidationDto } from '../../core/services/api.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';

import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: './send-bulk-sms-modal.component.html',
    styleUrls: ['./send-bulk-sms-modal.component.scss'],
    standalone: true,
    imports: [MatDialogModule, FormsModule, RouterLink, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class SendBulkSMSModalComponent {
  loadNumbers: ShipmentValidationDto[];
  invalidLoadNumberCount: number;
  errors: ShipmentValidationErrorDto[] = [];
  message: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShipmentValidationDto[],
    private dialogRef: MatDialogRef<SendBulkSMSModalComponent>)
  {
    this.loadNumbers = data;
    this.invalidLoadNumberCount = data.filter(f => !f.valid).length;
    data.forEach(f => {
      if(this.errors.some(s => s.errorMessage == f.error)) {
        this.errors.filter(s => s.errorMessage == f.error)[0].loadNumbers.push(f.loadNumber)
      }
      else {
        if(f.error) {
          this.errors.push({
            loadNumbers: [f.loadNumber],
            errorMessage: f.error
          })
        }
      }
    });
  }

  sendSMS() {
    this.dialogRef.close({loadNumbers: this.loadNumbers, message: this.message});
  }
}

export class ShipmentValidationErrorDto {
  loadNumbers: Number[] = [];
  errorMessage: string = "";
}
